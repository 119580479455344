<template>
  <div>
    <v-card class="elevation-2 timeline pBlock" v-for="(log, n) in logList" :key="n" elevation="0">
      <div class="time">{{ log.createdAt | datetime }}</div>
      <v-card-text class="body">
        <v-icon>mdi-plus-circle-outline</v-icon> {{ log.name }}
        <div>{{ log.body }}</div>
        <ul>
          <li v-for="(action, n) in log.details" :key="n">
            <span style="color: grey">{{action.attribute}}</span> : 
            <span style="color: orange">{{action.before}}</span> => 
            <span style="color: green">{{action.after}}</span>
          </li>
        </ul>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { formatMixin } from "@/helpers/mixins";

export default {
  mixins: [formatMixin],
  data() {
    return {};
  },

  props: {
    logs: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    logList() {
      let l = [];
      this.logs.forEach(log => {
        let o = {
          name: log.name,
          createdAt: log.createdAt,
          body: log.body
        };

        if (log.details) {
          o.details = log.details.split(",").map(action => {
            let p = action.split("|");
            return {
              attribute: p[0],
              before: this.formatObjVal(p[1]),
              after: this.formatObjVal(p[2])
            };
          })
        }
        l.push(o);
      });
      return l;
    }
  },

  methods: {
    formatObjVal: function(val) {
      if (!val || val == "undefined") return null;
      if (val && val.endsWith("000Z")) return this.getDateTime(val);
      return val
    }
  },

  components: {}
};
</script>

<style lang="scss" scoped>
.timeline .body {
  /*padding: 3px;*/
}
.timeline .time {
  float: right;
  font-size: 10px !important;
  padding: 2px;
}
.pBlock {
  //background-color: #efefef;
}
</style>
