<template>
  <DefaultLayout>
    <template v-slot:header>
      <v-toolbar-items class="hidden-md-and-down d-print-none" v-if="isAdmin || user.id == project.ownerId">
        <v-btn text color="primary" v-if="project.state == 'open'" @click="archivate()">
          <v-icon>mdi-archive-arrow-down-outline</v-icon>{{ $t("projects.actions.archive") }}
        </v-btn>
        <v-btn text color="primary" v-else @click="reActivate()"> <v-icon>mdi-archive-arrow-up-outline</v-icon>{{ $t("projects.actions.reactivate") }}</v-btn>
        <v-btn text color="red darken-2" @click="delProj()"> <v-icon left>mdi-delete</v-icon> {{ $t("projects.actions.delete") }} </v-btn>
      </v-toolbar-items>

      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-md-and-down d-print-none">
        <v-btn text color="accent" @click="$router.push(`/customers/${project.customerId}`)" v-if="$route.query.from == 'customer'">
          <v-icon>mdi-arrow-left-bold-outline</v-icon> {{ $t("project.backToCustomer") }}
        </v-btn>
        <v-btn text @click="scrollMeTo('actions')">{{ $t("projects.labels.info") }}</v-btn>
        <v-btn text v-for="(bookmark, i) in visibleBookmarks" :key="i" @click="scrollMeTo(bookmark.key)">
          <v-icon>{{ bookmark.icon }}</v-icon>
        </v-btn>
      </v-toolbar-items>

      <div class="hidden-md-and-down d-print-none">
        <v-menu offset-y class="hidden-md-and-down">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark v-on="on"> <v-icon left>mdi-plus</v-icon> {{ $t("projects.actions.add") }} </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(action, i) in detailActions" :key="i" @click="onAction(action.key)">
              <v-list-item-title>
                <v-icon>{{ action.icon }}</v-icon> {{ action.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <div class="hidden-lg-and-up d-print-none">
        <v-menu class="hidden-lg-and-down">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark v-on="on"> <v-icon left>mdi-arrow-down</v-icon> {{ $t("projects.labels.actions") }} </v-btn>
          </template>
          <v-list>
            <v-btn text color="primary" v-if="project.state == 'open'" @click="archivate()">
              <v-icon>mdi-archive-arrow-down-outline</v-icon> {{ $t("projects.actions.archive") }}
            </v-btn>
            <v-btn text color="primary" v-else @click="reActivate()">
              <v-icon>mdi-archive-arrow-up-outline</v-icon> {{ $t("projects.actions.reactivate") }}
            </v-btn>
            <v-btn text color="red" @click="delProj()"> <v-icon left>mdi-delete</v-icon> {{ $t("projects.actions.delete") }} </v-btn>
            <v-divider></v-divider>
            <v-btn text @click="scrollMeTo('actions')">{{ $t("projects.labels.info") }}</v-btn>
            <v-btn text v-for="(bookmark, i) in visibleBookmarks" :key="i" @click="scrollMeTo(bookmark.key)">
              <v-icon>{{ bookmark.icon }}</v-icon>
            </v-btn>
            <v-divider></v-divider>
            <v-list-item v-for="(action) in detailActions" :key="action.key" @click="onAction(action.key)">
              <v-list-item-title>
                <v-icon>{{ action.icon }}</v-icon> {{ action.text }}
              </v-list-item-title>
            </v-list-item>
            <template v-if="$route.query.from == 'customer'">
              <v-divider></v-divider>
              <v-btn text color="accent" @click="$router.push(`/customers/${project.customerId}`)">
                <v-icon>mdi-arrow-left-bold-outline</v-icon> {{ $t("project.backToCustomer") }}
              </v-btn>
            </template>
          </v-list>
        </v-menu>
      </div>
    </template>
    <v-layout>
      <v-flex>
        <ProjectDetailActions ref="actions" :project="project" :onUpdate="loadProject" />
        <ProjectBlock>
          <ProjectPosition :projectId="project.id" :projectPhaseId="project.projectPhaseId" class="mb-5" />
        </ProjectBlock>
        <v-alert v-if="project && project.state == 'archived'" border="left" type="warning" class="mx-5">
          {{ $t("projects.archivedMessage") }}
        </v-alert>

        <ProjectBlock title="Info" icon="mdi-information-variant">
        <!--
        <ProjectDetails ref="details" :project="project" :onUpdate="loadProject" :onAction="onAction" class="mb-5" />
-->
          <ProjectInfos ref="info" :project="project" :onUpdate="loadProject" :onAction="onAction" class="mb-5" />
        </ProjectBlock>

        <ProjectBlock v-if="project.notes && project.notes.length" ref="notes" title="Notes" icon="mdi-pencil-outline" :onAdd="onAction" actionKey="createNote">
          <Notes :notes="project.notes" :onAction="onAction" :onUpdate="onUpdate" />
        </ProjectBlock>

        <ProjectBlock
          v-if="project.tasks && project.tasks.length"
          ref="tasks"
          title="Tasks"
          icon="mdi-calendar-check-outline"
          :onAdd="onAction"
          actionKey="createTask"
        >
          <Tasks :tasks="project.tasks" :onAction="onAction" :showFilters="false" />
        </ProjectBlock>

        <ProjectBlock
          v-if="project.investments && project.investments.length"
          ref="investments"
          title="Banks"
          icon="mdi-bank-outline"
          :onAdd="onAction"
          actionKey="createInvestment"
        >
          <Investments :investments="project.investments" :onAction="onAction" :projectId="project.id" />
        </ProjectBlock>

        <ProjectBlock
          v-if="project.appointments && project.appointments.length"
          ref="appointments"
          title="Appointments"
          icon="mdi-calendar"
          :onAdd="onAction"
          actionKey="createAppointment"
        >
          <Appointments :appointments="project.appointments" :onAction="onAction" />
        </ProjectBlock>

        <ProjectBlock
          v-if="project.mails && project.mails.length"
          ref="mails"
          title="Mails"
          icon="mdi-email-multiple-outline"
          :onAdd="onAction"
          actionKey="sendMail"
        >
          <Mails :items="project.mails" :onUpdate="onUpdate" />
        </ProjectBlock>

        <ProjectBlock v-if="project.files && project.files.length" ref="files" title="Files" icon="mdi-file-multiple-outline" :onAdd="onAction" actionKey="">
          <Files :items="project.files" :complete="project.complete" />
        </ProjectBlock>
        <ProjectActionLogs ref="actionlogs" :logs="project.actionlogs" />
      </v-flex>
    </v-layout>
  </DefaultLayout>
</template>

<script>
import axios from "axios";

import { mapGetters } from "vuex";

import DefaultLayout from "@/layouts/default.vue";
import ProjectPosition from "@/components/projects/projectposition";
import ProjectInfos from "@/components/projects/projectinfos";

import ProjectActionLogs from "@/components/projects/projectactionlog.vue";
import ProjectDetailActions from "@/components/projects/projectdetailactions";

import ProjectBlock from "@/components/projects/projectblock";

import Files from "@/components/projects/projectfiles";
import Mails from "@/components/mails/maillist";
import Tasks from "@/components/tasks/tasklist";
import Investments from "@/components/banks/investments";
import Notes from "@/components/notes/noteslist";
import Appointments from "@/components/appointments/appointmentslist";

export default {
  name: "Projects",
  data() {
    return {
      project: {},
      drawer: true,
      actions: [
        { icon: "mdi-pencil-outline", text: this.$t("notes.pageTitle"), key: "notes" },
        { icon: "mdi-calendar-check-outline", text: this.$t("tasks.pageTitle"), key: "tasks" },
      ],
      bookmarks: [
        { icon: "mdi-pencil-outline", text: this.$t("notes.pageTitle"), key: "notes" },
        { icon: "mdi-calendar-check-outline", text: this.$t("tasks.pageTitle"), key: "tasks" },
        { icon: "mdi-bank-outline", text: this.$t("banks.pageTitle"), key: "investments" },
        { icon: "mdi-calendar", text: this.$t("calendar.pageTitle"), key: "appointments" },
        { icon: "mdi-email-multiple-outline", text: this.$t("mails.title"), key: "mails" },
        { icon: "mdi-file-multiple-outline", text: this.$t("projects.title"), key: "files" },
        { icon: "mdi-play-box-multiple-outline", next: this.$t("projects.logs"), key: "actionlogs" },
      ],
      detailActions: [
        { icon: "mdi-pencil-outline", key: "createNote", text: this.$t("notes.pageTitle") },
        { icon: "mdi-calendar-check", key: "createTask", text: this.$t("tasks.pageTitle") },
        { icon: "mdi-bank", key: "createInvestment", text: this.$t("banks.pageTitle") },
        { icon: "mdi-calendar", key: "createAppointment", text: this.$t("calendar.pageTitle") },
        { icon: "mdi-email-outline", key: "sendMail", text: this.$t("mails.pageTitle") },
      ],
    };
  },

  components: {
    DefaultLayout,
    ProjectPosition,
    ProjectInfos,
    ProjectBlock,
    ProjectActionLogs,
    ProjectDetailActions,
    Tasks,
    Files,
    Mails,
    Investments,
    Appointments,
    Notes,
  },

  computed: {
    ...mapGetters(["user", "isAdmin"]),
    visibleBookmarks: function() {
      return this.bookmarks.filter((b) => this.project[b.key] && this.project[b.key].length);
    },
  },

  created: function() {
    this.loadProject();
  },

  watch: {},

  methods: {
    onAction: function(key, param) {
      this.$refs.actions.showAction(key, param);
    },

    archivate: function() {
      this.$refs.info.saveState("archived");
      this.scrollMeTo("actions");
    },

    reActivate: function() {
      this.$refs.info.saveState("open");
    },

    delProj: function() {
      this.$refs.info.deleteProjectQuestion();
    },

    loadProject: function() {
      this.$store.dispatch("loading", true);
      if (this.$route.params.id) {
        axios
          .get(`/api/projects/${this.$route.params.id}`)
          .then((response) => {
            this.project = response.data;
            this.$store.dispatch("loading", false);
          })
          .catch((e) => {
            console.log(e);
            this.$store.dispatch("loading", false);
          });
      } else {
        this.reset();
      }
    },

    onUpdate: function() {
      this.loadProject();
    },

    scrollMeTo(refName) {
      var element = this.$refs[refName];
      this.$vuetify.goTo(element, {
        duration: 500,
        offset: 10,
        easing: "easeInOutCubic",
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
