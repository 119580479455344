<template>
  <div class="editBlock">
    <span class="displayBlock" v-if="!show" @click="show = !show">
      <span>{{ value }} <v-icon>mdi-pencil-outline</v-icon></span>
    </span>
    <v-text-field v-else dense v-model="value" :tabIndex="tabIndex" @input="$emit('saved', value)" @blur="show = false">
      <v-icon slot="append" @click="show = !show">mdi-content-save-outline</v-icon>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: "editonclick",
  components: {},
  data: function() {
    return {
      show: false,
    };
  },
  props: {
    value: {
      type: String,
      default: () => "",
    },
    tabIndex: {
      type: Number,
      default: () => 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.editBlock {
  width: 100%;
  .displayBlock {
    cursor: pointer;
  }
}
</style>
