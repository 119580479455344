<template>
  <v-container fluid class="pa-0 timeLine notes">
    <Dlg ref="dialogs" :title="$t('notes.delete.title')" :body="$t('notes.delete.body')" :onOk="deleteNote" />
    <v-timeline dense clipped align-top v-if="notes.length">
      <v-timeline-item color="grey" small v-for="note in notes" :key="note.id" class="timeElement">
        <div class="timeHeader" :style="timeHeaderStyle">
          {{ note.updatedAt | datetime }}
        </div>
        <div class="timeContent note">
          <div class="float-right">
            <v-btn icon @click="onAction('createNote', { id: note.id })">
              <v-icon color="lime">mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon @click="deleteNoteQuestion(note.id)">
              <v-icon color="red">mdi-delete</v-icon>
            </v-btn>
          </div>
          <div v-html="note.text"></div>
        </div>
      </v-timeline-item>
    </v-timeline>
    <div v-else>{{ $t("notes.list.noResults") }}</div>
  </v-container>
</template>

<script>
import axios from "axios";
import { getColor } from "@/services/theme";
import { formatMixin, formMixin } from "@/helpers/mixins";
import Dlg from "@/components/_common/dialogs";

export default {
  mixins: [formatMixin, formMixin],

  data() {
    return {};
  },

  props: {
    notes: {
      type: Array,
      default: () => [],
    },
    onAction: {
      type: Function,
    },
  },
  
  computed: {
    timeHeaderStyle: function() {
      return `color: ${getColor("accent")}`
    },
  },

  mounted() {},

  methods: {
    deleteNoteQuestion: function(id) {
      this.$refs.dialogs.show(id);
    },
    deleteNote: function(id) {
      axios
        .delete(`/api/notes/${id}`)
        .then(() => {
          this.notes = this.notes.filter((n) => n.id != id);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },

  components: {
    Dlg,
  },
};
</script>

<style lang="scss" scoped>
.notes {
  margin-right: 1rem;
  left: 24px;
}
</style>
