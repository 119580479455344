<template>
  <div>
    <v-navigation-drawer v-model="sw.createNote" absolute temporary stateless right width="75%">
      <CreateNote :projectId="project.id" :onSuccess="onUpdate" :onClose="closePanel" ref="createNote" />
    </v-navigation-drawer>

    <v-navigation-drawer v-model="sw.createTask" absolute temporary stateless right width="75%">
      <CreateTask :projectId="project.id" :onSuccess="onUpdate" :onClose="closePanel" ref="createTask" />
    </v-navigation-drawer>

    <v-navigation-drawer v-model="sw.createInvestment" absolute temporary stateless right width="75%">
      <CreateInvestment :projectId="project.id" :onSuccess="onUpdate" :onClose="closePanel" ref="createInvestment" />
    </v-navigation-drawer>

    <v-navigation-drawer v-model="sw.createAppointment" absolute temporary stateless right width="75%">
      <CreateAppointment :projectId="project.id" :onSuccess="onUpdate" :onClose="closePanel" ref="createAppointment" />
    </v-navigation-drawer>

    <v-navigation-drawer v-model="sw.sendMail" absolute temporary stateless right width="75%">
      <SendMail :projectId="project.id" :onSuccess="onUpdate" :onClose="closePanel" ref="sendMail" />
    </v-navigation-drawer>
  </div>
</template>

<script>
import axios from "axios";

import CreateNote from "@/components/notes/notesedit";
import CreateTask from "@/components/tasks/taskcreate";
import CreateInvestment from "@/components/banks/investmentcreate";
import CreateAppointment from "@/components/appointments/appointmentcreate";
import SendMail from "@/components/mails/mailsend";

export default {
  mixins: [],

  components: {
    SendMail,
    CreateTask,
    CreateNote,
    CreateAppointment,
    CreateInvestment,
  },

  props: {
    project: {
      type: Object,
      default: () => {},
    },
    onUpdate: {
      type: Function,
    },
  },

  data() {
    return {
      //drawer: false,
      sw: {
        createNote: false,
        createTask: false,
        createAppointment: false,
        sendMail: false,
        selectCustomer: false,
        createInvestment: false,
        archive: false,
      },
    };
  },

  methods: {
    showAction: function(refKey, params) {
      if (!refKey || refKey == "") {
        return;
      }
      if (refKey == "deleteProject") {
        this.deleteProject();
      } else {
        Object.keys(this.sw).forEach((element) => {
          this.sw[element] = false;
        });
        this.sw[refKey] = true;
        this.$refs[refKey].load(params);
        this.$vuetify.goTo(1, {
          duration: 500,
          offset: 10,
          easing: "easeInOutCubic",
        });
      }
    },

    closePanel: function() {
      Object.keys(this.sw).forEach((element) => {
        this.sw[element] = false;
      });
    },

    deleteProject: function() {
      if (this.$route.params.id) {
        axios
          .delete(`/api/projects/${this.$route.params.id}`)
          .then(() => {
            this.$router.push("/projects");
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.reset();
      }
    },
  },
};
</script>

<style lang="scss"></style>
