<template>
  <v-card elevation="1" class="ma-5">
    <v-app-bar v-if="title || icon" flat dense :style="style">
      <v-app-bar-nav-icon>
        <v-icon dark>{{ icon }}</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title
        ><h4>{{ title }}</h4></v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn icon v-if="actionKey" color="primary">
        <v-icon left dark @click="onAdd(actionKey)">mdi-plus-circle</v-icon>
      </v-btn>
    </v-app-bar>
    <v-card-text class="pa-0">
      <slot />
    </v-card-text>
  </v-card>
</template>

<script>
import { getColor } from "@/services/theme";

export default {
  name: "projectDetailBlock",
  props: {
    title: {
      type: String,
    },
    icon: {
      type: String,
    },
    color: {
      type: String,
    },
    actionKey: {
      type: String,
    },
    onAdd: {
      type: Function,
    },
  },
  computed: {
    style: function() {
      return `background: ${getColor("navbg")}; background: linear-gradient(0deg, transparent 10%, ${getColor("navbg")} 75%;`;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
