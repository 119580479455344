<template>
  <v-card elevation="0">
    <PanelHeader :onSave="save" :title="$t('notes.edit.title')" :tabIndex="5002" :onClose="onClose" />
    <v-card-text>
      <v-layout row wrap>
        <v-col cols="12" md="12">
          {{ $t("notes.edit.body") }}
          <ckeditor :editor="editor" v-model="note.text" :config="editorConfig" class="editor" :tabIndex="5001"></ckeditor>
        </v-col>
        <v-col cols="12" md="12">
          <v-alert text icon="mdi-help" border="left" color="green" block>
            {{ $t("notes.edit.help") }}
          </v-alert>
        </v-col>
      </v-layout>
      <pre>{{ note }}</pre>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import { formatMixin, formMixin, richMixin } from "@/helpers/mixins";

import PanelHeader from "@/components/_common/panelheader";

export default {
  mixins: [formatMixin, formMixin, richMixin],

  components: {
    PanelHeader
  },

  props: {
    onSuccess: {
      type: Function
    },
    projectId: {
      type: String,
      default: () => ""
    },
    onClose: {
      type: Function
    }
  },

  data() {
    return {
      note: {
        body: ""
      }
    };
  },

  created() {
    this.note.projectId = this.projectId;
  },

  methods: {
    load: function(params) {
      if (params && params.id) {
        axios
          .post(`/api/notes/${params.id}`)
          .then(response => {
            this.note = response.data;
          })
          .catch(e => {
            console.log(e);
            this.$store.dispatch("showSnackMessage", { message: e });
          });
      } else {
        this.note = {
          projectId: this.projectId
        };
      }
    },

    save: function() {
      axios
        .post(`/api/notes/${this.note.id || ""}`, this.note)
        .then(msg => {
          this.$store.dispatch("showSnackMessage", { message: "Data saved" });
          this.note = {};
          this.onSuccess(msg.data);
          this.onClose();
        })
        .catch(e => {
          console.log(e);
          this.$store.dispatch("showSnackMessage", { message: e });
        });
    },
  }
};
</script>
