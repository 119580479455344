<template>
  <div class="breadcrumb flat" v-bind:class="{ dark: isDark }">
    <a href="#" v-for="(phase, n) in phases" :key="n" @click="save(phase)" v-bind:class="{ active: currentProjectPhaseId == phase.id }"> {{ phase.name }}</a>
  </div>
</template>

<script>
import axios from "axios";
import { isDark } from "@/services/theme";
import { formatMixin, formMixin } from "@/helpers/mixins";

export default {
  mixins: [formatMixin, formMixin],

  data: () => ({
    phases: [],
    currentProjectPhaseId: "",
  }),

  props: {
    projectPhaseId: {
      type: String,
      default: () => "",
    },
    projectId: {
      type: String,
      default: () => "",
    },
  },

  computed: {
    isDark: function () {
      return isDark();
    },
  },

  watch: {
    projectPhaseId: function (newVal) {
      this.currentProjectPhaseId = newVal;
    },
  },

  mounted() {
    this.loadPhases();
  },

  methods: {
    loadPhases: function () {
      this.$store.dispatch("loading", true);
      axios
        .get(`/api/projectphases`)
        .then((response) => {
          this.phases = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    save: function (phase) {
      axios
        .post(`/api/projects/${this.projectId}`, {
          projectPhaseId: phase.id,
        })
        .then(() => {
          this.$store.dispatch("showSnackMessage", { message: "Data saved" });
          this.currentProjectPhaseId = phase.id;
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("showSnackMessage", { message: e });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb {
  display: inline-block;
  overflow: hidden;
  border-radius: 5px;
  counter-reset: flag;
  display: flex;
  flex-wrap: wrap;

  a {
    flex: 1 0 14%;
    text-decoration: none;
    outline: none;
    display: block;
    float: left;
    font-size: 12px;
    line-height: 48px;
    padding: 0 10px 0 65px;
    position: relative;
    white-space: nowrap;
  }

  a:first-child {
    padding-left: 46px;
    border-radius: 5px 0 0 5px;
  }
  a:first-child:before {
    left: 14px;
  }
  a:last-child {
    border-radius: 0 5px 5px 0;
    padding-right: 20px;
  }

  a:after {
    content: "";
    position: absolute;
    top: 0;
    right: -24px;
    width: 48px;
    height: 48px;
    transform: scale(0.707) rotate(45deg);
    z-index: 1;
    box-shadow: 2px -2px 0 2px rgba(0, 0, 0, 0.4), 3px -3px 0 2px rgba(255, 255, 255, 0.1);
    border-radius: 0 5px 0 50px;
  }

  a:last-child:after {
    content: none;
  }

  a:before {
    content: counter(flag);
    counter-increment: flag;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    line-height: 20px;
    margin: 14px 0;
    position: absolute;
    text-align: center;
    top: 0;
    left: 35px;
    font-weight: bold;
  }
}

.flat {
  a,
  a:after {
    background: #efefef;
    transition: all 0.5s;
  }
  a:before {
    background: white;
    box-shadow: 0 0 0 1px #ccc;
  }

  a.active,
  a.active:after {
    background: #2e5266;
    color: white;
  }
  a.active:before {
    color: #2e5266;
  }

  a:hover,
  a:hover:after {
    background: #cddc39;
  }

  &.dark {
    a,
    a:after {
      background: #37474f;
    }
    a:before {
      color: black;
    }

    a.active,
    a.active:after {
      background: #ffa726;
      color: black;
    }

    a:hover,
    a:hover:after {
      background: #0097A7;
      color: white;
    }
    
  }
}
</style>
