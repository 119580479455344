<template>
  <v-card elevation="0" class="pInfo ma-0 pa-0">
    <Dlg ref="delDlg" :title="$t('projects.delete.title')" :body="$t('projects.delete.body')" :onOk="deleteProject" />
    <div class="float-right pa-5">{{ project.identifier }}</div>
    <v-card-title>
      <v-row>
        <v-col cols="10">
          <EditOnClick :value="project.name" :tabIndex="6301" @saved="project.name = $event" />
        </v-col>
        <v-col cols="2" v-if="canEditIdentifyer">
          <v-text-field v-model="project.identifier" :label="$t('projects.identifier')" class="ma-0 pa-0"></v-text-field>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="pInfoContent">
      <v-row>
        <v-col cols="3" class="colHeader"> {{ $t("projects.infos.customer") }}: </v-col>
        <v-col cols="9">
          <v-autocomplete
            v-model="customer"
            :items="customers"
            :search-input.sync="search"
            hide-no-data
            hide-selected
            item-text="name"
            item-value="id"
            :placeholder="$t('projects.infos.customerSearch')"
            prepend-inner-icon="mdi-account"
            clearable
            rounded
            outlined
            dense
            return-object
            hide-details
            :tabIndex="6302"
          ></v-autocomplete>
          <div v-if="customer" style="padding: 5px 0 5px 15px;">
            <v-icon v-if="false" @click="sendMail(customer.email, seller.email)">mdi-email-outline</v-icon>
            <a class="mailto" :href="mailToCustomerLink" target="_new">
              <v-icon>mdi-email-edit-outline</v-icon>
              {{ customer.email }}
            </a>
            <template v-if="customer.phone"><v-icon>mdi-phone</v-icon> {{ customer.phone }}</template>
            <template v-if="customer.mobile"><v-icon>mdi-cellphone-basic</v-icon> {{ customer.mobile }}</template>
          </div>
        </v-col>

        <v-col cols="3" class="colHeader"> {{ $t("projects.infos.volume") }}: </v-col>
        <v-col cols="3" class="colHeader">
          <EditOnClick :value="project.volume ? project.volume.toString() : '0'" :tabIndex="6303" @saved="project.volume = $event" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" class="colHeader"> {{ $t("projects.infos.projectType") }}: </v-col>
        <v-col cols="9">
          <v-menu v-model="data.showProjectTypes" absolute close-on-click close-on-content-click>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" rounded outlined v-on="on" :tabIndex="6304">
                {{ projectTypeName | fallback($t("common.labels.undefined")) }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in projectTypes" :key="index" @click="project.projectTypeId = item.id">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>

        <v-col cols="3" class="colHeader"> {{ $t("projects.infos.seller") }}: </v-col>
        <v-col cols="9">
          <v-menu v-model="data.showSellers" absolute close-on-click close-on-content-click>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" rounded outlined v-on="on" :tabIndex="6305">
                {{ seller.name | fallback($t("common.labels.undefined")) }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in sellers" :key="index" @click="project.sellerId = item.id">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <div v-if="seller.name" style="padding: 5px 0 5px 15px;">
            <v-icon v-if="false" @click="sendMail(seller.email)">mdi-email-outline</v-icon>
            <a class="mailto" :href="mailToSellerLink" target="_new">
              <v-icon>mdi-email-edit-outline</v-icon>
            </a>
            {{ seller.email }}
            <v-icon>mdi-phone</v-icon> {{ seller.phone }} <v-icon>mdi-domain</v-icon> {{ seller.organisation }}
          </div>
        </v-col>

        <v-col cols="3" class="colHeader"> {{ $t("projects.infos.owner") }}: </v-col>
        <v-col cols="9">
          <v-menu v-model="data.showOwner" absolute close-on-click close-on-content-click>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" rounded outlined v-on="on" :tabIndex="6306">
                {{ owner.name }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in users" :key="index" @click="project.ownerId = item.id">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>

        <!--
        <v-col cols="3" class="colHeader"> {{ $t("projects.infos.runtime") }}: </v-col>
        <v-col cols="9">
          <EditOnClick :value="project.runtime || 0" :tabIndex="10" @saved="project.runtime = $event" />
        </v-col>
-->
        <v-col cols="12">
          <v-btn class="float-right" :color="changed ? 'primary' : 'secondary'" @click="saveInfos()" :tabIndex="6308">{{ $t("projects.infos.save") }}</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";

import { formatMixin, formMixin, richMixin } from "@/helpers/mixins";

import EditOnClick from "@/components/_common/editonclick";
import Dlg from "@/components/_common/dialogs";

export default {
  mixins: [formatMixin, formMixin, richMixin],
  components: {
    EditOnClick,
    Dlg,
  },
  props: {
    project: {
      type: Object,
      default: () => {},
    },
    onUpdate: {
      type: Function,
    },
    onAction: {
      type: Function,
    },
  },

  data() {
    return {
      data: {
        showSellers: false,
        showOwner: false,
        showVolumeEdit: false,
      },
      fab: false,
      projectTypes: [],
      sellers: [],
      users: [],
      customer: null,
      customers: [],
      search: null,
      customerId: null,
      changed: true,
      hiddenMail: "kls@danysan.com",
      canEditIdentifyer: false,
    };
  },

  computed: {
    seller() {
      let s = this.sellers.find((element) => element.id == this.project.sellerId);
      if (s) return s;
      return {};
    },
    owner() {
      let o = this.users.find((element) => element.id == this.project.ownerId);
      if (o) return o;
      return {};
    },
    projectTypeName() {
      let s = this.projectTypes.find((element) => element.id == this.project.projectTypeId);
      if (s) return s.name;
      return null;
    },
    mailToCustomerLink() {
      let str = `mailto:${this.customer.email}?subject=KLS - [${this.project.identifier}] - &bcc=${this.hiddenMail}`;
      if (this.seller.email) {
        str += `&cc=${this.seller.email}`;
      }
      return str;
    },
    mailToSellerLink() {
      return `mailto:${this.seller.email}?subject=KLS - [${this.project.identifier}] - &bcc=${this.hiddenMail}`;
    },
  },

  mounted() {
    this.customerId = this.project ? this.project.customerId : null;
    this.canEditIdentifyer = this.project && !this.project.identifier;
    this.loadDatas();
  },

  watch: {
    project: function(p) {
      this.customer = p.customer;
    },
  },

  methods: {
    loadDatas: function() {
      axios
        .get(`/api/sellers`)
        .then((response) => {
          this.sellers = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
      axios
        .get(`/api/projecttypes`)
        .then((response) => {
          this.projectTypes = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
      axios
        .get(`/api/users`)
        .then((response) => {
          this.users = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
      axios
        .get(`/api/customers`)
        .then((response) => {
          this.customers = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    saveInfos: function() {
      axios
        .post(`/api/projects/${this.project.id}`, {
          name: this.project.name,
          importance: this.project.importance,
          volume: this.project.volume,
          projectTypeId: this.project.projectTypeId,
          sellerId: this.project.sellerId,
          ownerId: this.project.ownerId,
          customerId: this.customer ? this.customer.id : null,
          identifier: this.project.identifier || null
        })
        .then((res) => {
          this.$store.dispatch("showSnackMessage", { message: "Data saved" });
          this.onUpdate(res.data);
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("showSnackMessage", { message: e });
        });
    },
    saveState: function(state) {
      axios
        .post(`/api/projects/${this.project.id}`, {
          state: state,
        })
        .then((res) => {
          this.$store.dispatch("showSnackMessage", { message: "Data saved" });
          this.onUpdate(res.data);
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("showSnackMessage", { message: e });
        });
    },
    deleteProjectQuestion: function() {
      this.$refs.delDlg.show();
      //this.confirmDialog(this.$t('projects.delete.title'), this.$t('projects.delete.body'), this.deleteProject);
    },
    deleteProject: function() {
      this.onAction("deleteProject");
    },
    sendMail: function(to, cc) {
      this.onAction("sendMail", {
        to: to,
        cc: cc,
        subject: `[${this.project.identifier}] - `,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pInfo {
  //background-color: #ffffff;

  .pInfoContent {
    .infoActions {
      float: right;
    }
    .colHeader {
      line-height: 40px;
    }
    .mailto {
      text-decoration: none;
    }
  }
}
</style>
