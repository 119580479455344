<template>
  <v-card elevation="0">
    <PanelHeader :title="$t('banks.selector.title')" :tabIndex="1112" :onSave="save" :onClose="onClose" />
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="inv.bankId"
                :items="banks"
                :search-input.sync="search"
                hide-selected
                item-text="name"
                item-value="id"
                :label="$t('banks.selector.select')"
                prepend-icon="mdi-bank"
                :tabIndex="1101"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12" md="4">
              <v-text-field v-model="inv.should" :label="$t('banks.edit.form.should')" v-currency="fPercent" :tabIndex="1102"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="inv.effective" :label="$t('banks.edit.form.effective')" v-currency="fPercent" :tabIndex="1103"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="inv.rate" :label="$t('banks.edit.form.rate')" v-currency="fEuro" :tabIndex="1104"></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12" md="5">
              <v-text-field v-model="inv.sum" :label="$t('banks.edit.form.sum')" v-currency="fEuro" :tabIndex="1105"></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              % <v-icon color="lime" @click="dg = true" @keyup.native.enter="dg = true" :tabIndex="1106">mdi-arrow-right-circle</v-icon>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field v-model="inv.commission" :label="$t('banks.edit.form.commission')" v-currency="fEuro" :tabIndex="1107"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="9">
              <DropDate :model="inv.invend" :tabIndex="1108" :onClose="setDate" :min="min" :max="max" :label="$t('banks.edit.form.invend')" />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field v-model="inv.duration" :label="$t('banks.edit.form.duration')" suffix="Jahre" disabled></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field v-model="inv.repayment" :label="$t('banks.edit.form.repayment')" v-currency="fPercent" :tabIndex="1109"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="inv.specialrepayment"
                :label="$t('banks.edit.form.specialrepayment')"
                v-currency="fPercent"
                :tabIndex="1110"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="inv.bzfree" :label="$t('banks.edit.form.bzfree')" suffix="Monate" :tabIndex="1111"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-alert text icon="mdi-help" border="left" color="green" block>
                {{ $t("banks.edit.form.help") }}
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
        <pre>{{ inv }}</pre>
      </v-form>
      <v-dialog v-model="dg" max-width="300px">
        <v-card>
          <v-card-title class="headline lime lighten-2" primary-title>
            {{ $t("banks.edit.comission.percentage") }}
          </v-card-title>
          <v-card-text class="mt-5">
            <v-text-field regular v-model="comPercentil" single :label="$t('banks.edit.comission.howMany')" v-currency="fPercent"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="dg = false">
              {{ $t("common.dialog.cancel") }}
            </v-btn>
            <v-divider />
            <v-btn color="primary" @click="setCom()">
              {{ $t("common.dialog.ok") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import PanelHeader from "@/components/_common/panelheader";
import DropDate from "@/components/_common/dropdate";
import { formMixin } from "@/helpers/mixins";

export default {
  mixins: [formMixin],

  data() {
    return {
      projects: [],
      banks: [],
      search: null,
      dg: false,
      value: 1000,
      valid: false,
      inv: {
        should: 0,
        effective: 0,
        rate: 0,
        sum: 0,
        commission: 0,
        duration: 0,
        repayment: 0,
        specialrepayment: 0,
        bzfree: 0,
        invend: null,
      },
      comPercentil: 5,
    };
  },

  watch: {},

  computed: {
    min: function() {
      let dt = new Date();
      dt.setFullYear(dt.getFullYear() - 1);
      return dt.toISOString().substr(0, 10);
    },
    max: function() {
      let dt = new Date();
      dt.setFullYear(dt.getFullYear() + 50);
      return dt.toISOString().substr(0, 10);
    },
  },

  props: {
    projectId: {
      type: String,
      default: () => {},
    },
    onSuccess: {
      type: Function,
    },
    onClose: {
      type: Function,
    },
  },

  mounted() {
    this.loadBanks();
    this.inv.projectId = this.projectId;
  },

  methods: {
    setDate: function(dt) {
      let a = { ...this.inv };
      a.invend = new Date(dt);
      a.duration = a.invend.getFullYear() - new Date().getFullYear();
      this.inv = a;
    },

    setCom: function() {
      let s = this.clearNumber(this.inv.sum);
      let p = this.clearNumber(this.comPercentil);

      this.inv.commission = s * (p / 100);
      this.dg = false;
    },

    clearNumber: function(val) {
      if (!val) {
        return 0;
      }
      let v = val.toString().replace(/[^0-9,]+/g, "");
      let w = v.replace(",", ".");
      return parseFloat(w);
    },

    load: function(params) {
      this.comPercentil = 5;
      if (params && params.id) {
        axios
          .get(`/api/investments/${params.id}`, this.inv)
          .then((res) => {
            this.inv = res.data;
            if (this.inv.invend) {
              this.inv.invend = new Date(this.inv.invend);
            }
          })
          .catch((e) => {
            console.log(e);
            this.$store.dispatch("showSnackMessage", { message: e });
          });
      } else {
        this.$refs.form.reset();
        this.inv = {
          projectId: this.projectId,
        };
      }
    },

    loadBanks: function() {
      axios
        .get(`/api/banks`, {
          bankIds: this.bankIds,
        })
        .then((res) => {
          this.banks = res.data;
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("showSnackMessage", { message: e });
        });
    },

    loadInvestment: function(id) {
      axios
        .post(`/api/investments/${id}`)
        .then((res) => {
          this.inv = res.data;
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("showSnackMessage", { message: e });
        });
    },

    save: function() {
      axios
        .post(`/api/investments/${this.inv.id || ""}`, {
          projectId: this.inv.projectId,
          bankId: this.inv.bankId,
          should: this.clearNumber(this.inv.should),
          effective: this.clearNumber(this.inv.effective),
          rate: this.clearNumber(this.inv.rate),
          sum: this.clearNumber(this.inv.sum),
          commission: this.clearNumber(this.inv.commission),
          duration: this.inv.duration,
          repayment: this.clearNumber(this.inv.repayment),
          specialrepayment: this.clearNumber(this.inv.specialrepayment),
          bzfree: this.inv.bzfree,
          invend: this.inv.invend,
        })
        .then((res) => {
          this.$store.dispatch("showSnackMessage", { message: "Data saved." });
          this.onSuccess(res.data);
          this.onClose();
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("showSnackMessage", { message: e });
        });
    },
  },

  components: {
    PanelHeader,
    DropDate,
  },
};
</script>

<style lang="scss" scoped>
.actions {
  text-align: right;
}
</style>
