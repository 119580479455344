<template>
  <v-container fluid class="pa-0 inv">
    <Dlg ref="dialogs" :title="$t('investments.delete.title')" :body="$t('investments.delete.body')" :onOk="deleteInv" />

    <div v-if="investments && investments.length">
      <div v-for="inv in investments" :key="inv.id" class="invBlock">
        <h4 :style="bankHeaderStyle">{{ inv.bank ? inv.bank.name : "" }}</h4>
        <v-row :class="{ archived: inv.state == 'archived' }" class="invCt">
          <v-col cols="11" class="py-0">
            <v-row>
              <v-col cols="12" lg="3" md="4" sm="6" class="pa-0">
                {{ $t("banks.edit.form.should") }} / {{ $t("banks.edit.form.effective") }}: <strong>{{ inv.should }} % / {{ inv.effective }} %</strong>
              </v-col>
              <v-col cols="12" lg="3" md="4" sm="6" class="pa-0">
                {{ $t("banks.edit.form.rate") }}: <strong>{{ inv.rate }} €</strong>
              </v-col>
              <v-col cols="12" lg="3" md="4" sm="6" class="pa-0">
                {{ $t("banks.edit.form.specialrepayment") }}: <strong>{{ inv.specialrepayment }} %</strong>
              </v-col>
              <v-col cols="12" lg="3" md="4" sm="6" class="pa-0">
                {{ $t("banks.edit.form.commission") }}: <strong>{{ inv.commission }} €</strong>
              </v-col>
              <v-col cols="12" lg="3" md="4" sm="6" class="pa-0">
                {{ $t("banks.edit.form.sum") }}: <strong>{{ inv.sum }} €</strong>s
              </v-col>
              <v-col cols="12" lg="3" md="4" sm="6" class="pa-0">
                {{ $t("banks.edit.form.bzfree") }}: <strong>{{ inv.bzfree }} {{ $t("common.labels.months") }}</strong>
              </v-col>
              <v-col cols="12" lg="3" md="4" sm="6" class="pa-0">
                {{ $t("banks.edit.form.duration") }}: <strong>{{ inv.duration }} {{ $t("common.labels.years") }}</strong>
              </v-col>
              <v-col cols="12" lg="3" md="4" sm="6" class="pa-0">
                {{ $t("banks.edit.form.repayment") }}: <strong>{{ inv.repayment }} %</strong>
              </v-col>
              <v-col cols="12" lg="3" md="4" sm="6" class="pa-0">
                {{ $t("banks.edit.form.invend") }}: <strong>{{ inv.invend | datetime }}</strong>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="1">
            <div v-if="inv.state != 'archived'" class="actioncol">
              <v-icon right @click="onAction('createInvestment', { id: inv.id })" color="lime">mdi-pencil</v-icon>
              <v-icon right @click="deleteInvQuestion(inv.id)" color="red">mdi-delete</v-icon>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else>{{ $t("banks.list.noResults") }}</div>
  </v-container>
</template>

<script>
import axios from "axios";
import { getColor } from "@/services/theme";
import Dlg from "@/components/_common/dialogs";
import { formatMixin } from "@/helpers/mixins";

export default {
  mixins: [formatMixin],

  data() {
    return {};
  },

  components: {
    Dlg,
  },

  props: {
    projectId: {
      type: String,
      default: () => {},
    },
    investments: {
      type: Array,
      default: () => [],
    },
    onAction: {
      type: Function,
    },
  },

  computed: {
    bankHeaderStyle: function() {
      return `color: ${getColor("accent")}`
    },
  },

  mounted() {},

  methods: {
    deleteInvQuestion: function(id) {
      this.$refs.dialogs.show(id);
    },

    deleteInv: function(id) {
      axios
        .delete(`/api/investments/${id}`)
        .then(() => {
          this.investments = this.investments.filter((i) => i.id != id);
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("showSnackMessage", { message: e });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.inv {
  margin-bottom: 1rem;
  padding-bottom: 1rem;

  h4 {
    color: rgb(103, 58, 183);
  }

  .invBlock{
    padding: 1rem;
  }

  .invCt {
    //border-radius: 5px;
    border-left: 3px solid #aaaaaa;
    //background-color: white;
    margin: 1rem 0 0 2rem;
    padding: 0 1rem;
  }

  .actioncol {
    float: right;
    text-align: right;
  }

  .archived {
    text-decoration: line-through;
  }
}
</style>
