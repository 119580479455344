<template>
  <v-container fluid grid-list-xl>
    <v-checkbox v-model="cmptl" label="Completed"></v-checkbox>
    
    <v-text-field label="Filled" placeholder="Placeholder" filled v-model="path"></v-text-field>
    <a :href="`file:${path}`">{{path}}</a> - 
    <input type=button :onClick="`alert('aop');parent.location='file:///${path}'`" value='Users'>
    <v-treeview v-model="tree" :open="open" :items="items" activatable item-key="name" open-on-click open-all dense>
      <template v-slot:prepend="{ item, open }">
        <v-icon v-if="item.type != 'file'" color="blue">
          {{ open ? "mdi-folder-open" : "mdi-folder" }}
        </v-icon>
        <v-icon v-else>
          {{ files[item.extension] || "mdi-file-outline" }}
        </v-icon>
      </template>
    </v-treeview>
    
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    open: ["public"],
    path: "//192.168.223.25/transfer",
    files: {
      ".html": "mdi-language-html5",
      ".js": "mdi-nodejs",
      ".json": "mdi-json",
      ".md": "mdi-markdown",
      ".pdf": "mdi-file-pdf",
      ".png": "mdi-file-image",
      ".txt": "mdi-file-document-outline",
      ".xls": "mdi-file-excel",
    },
    tree: [],
    cmptl: false,
  }),

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    complete: {
      type: Boolean,
      default: () => false,
    },
  },

  created() {
    this.cmptl = this.complete;
  },

  watch: {
    cmptl: function(val) {
      console.log(val)
      this.setComplete(val);
    }
  },

  methods: {
    setComplete: function(val) {
      this.$store.dispatch("loading", true);
      axios
        .post(`/api/projects/${this.$route.params.id}`, {
          complete: val,
        })
        .then(() => {
          this.$store.dispatch("loading", false);
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("loading", false);
        });
    },
  },
};
</script>
